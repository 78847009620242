import { Box, Button, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import ProDesc from './prodesc';
import {Link} from 'react-router-dom'
import './product.css'
import { FormattedMessage } from 'react-intl';

class Product extends Component{
    render(){
        return(
            <Box className='Body'>
                <Box className='LongTextContainer'>
                    <Typography variant='h1' className='LongTextHeader'>
                        <FormattedMessage
                        id="ProductTitle"
                        description="Products Title"
                        defaultMessage="Our Product"/>
                    </Typography>
                    <ProDesc />
                    <Box className='SubSection'>
                        <Typography variant='h3'>
                            <FormattedMessage
                            id="Interested"
                            description="Interested?"
                            defaultMessage="Interested?"
                            />
                        </Typography>
                        <Button variant='contained' color='primary' component={Link} to='/contact'>
                            <FormattedMessage
                            id="CTAProduct"
                            description="CTAProduct"
                            defaultMessage="Contact Us"
                            />
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default Product