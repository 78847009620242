import { Box, Typography } from '@material-ui/core';
import React, { Component } from 'react';

class About extends Component{
    render(){
        return(
            <Box className='Body'>
                <Box className='LongTextContainer'>
                    <Typography variant='h1' className='LongTextHeader'>
                        About Us
                    </Typography>
                    <Typography className='LongTextContent'>
                    Oysters are a special local product from Hiroshima, and oyster shell disposal is one of the highest and most important problems for the fishing industry in Hiroshima. Although oyster shells are used as animal feed and fertilizer, thousands of tons are still wasted each year. <br/>
                    To reduce oyster shell waste, PT ARA MULIA CITRA together with TRUSTONE Co., Ltd. Hiroshima, Japan with the support of the Hiroshima Prefecture Government policy and with the intention of ensuring that the environment is maintained, we developed a new and first product in Indonesia, namely Oyster shell disinfectant, which material we took from Hiroshima, and as a new method of recycling oyster shells. <br/>
                    This product is halal because it is only made from oyster shells and water, so it is safe to use and guaranteed for the environment and living things. Before being marketed, we have tested the product in the European Standards (EN: European Norms) Laboratory: Bacterial / Fungicidal and Virucidal Activity Test and National Laboratory Tests.<br/>
                    <br/>
                    Licensed by: Trustone Co., Ltd. Japan <br/>
                    LEGALITY: KEMENKES RI PKD 20502022763


                    </Typography>
                </Box>
            </Box>
        )
    }
}

export default About