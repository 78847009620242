import React, { Component } from 'react';
import { Box, Button, Typography  } from '@material-ui/core';
import './hero.css'
import { FormattedMessage } from 'react-intl';

class Hero extends Component{
    render(){
        return(
            <Box className="Hero">
                <Box className="ContentText">
                    <Typography align='center' variant='h1'>
                        <FormattedMessage id="hero1" description="headline" defaultMessage="Clean Safely"/>
                    </Typography>
                    <Typography className='Subtitle' align='center' variant='h4'>
                        <FormattedMessage id="hero2" description="subtitle" defaultMessage="with Oyster-Shell-Based Desinfectants." />
                    </Typography>
                    <Typography align='center' variant='h6'>
                        <FormattedMessage id="hero3" description="selling point" defaultMessage="No Alcohol | Safe" />
                    </Typography>
                    <br />
                    <Box className="HeroButtonGroup">
                        <Button className='CTA1' color='secondary' variant='contained'>Contact Us</Button>
                        <Button color='secondary' variant='outlined'>Read More</Button>
                    </Box>
                </Box>
                {/* <Box className="ImageContainer">
                    <img alt = "Arami" src = {HeroImg} width ="100%"/>
                </Box> */}
            </Box>
        )
    }
}

export default Hero