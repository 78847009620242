import React from 'react';
import Header from './container/header/header';
import Footer from './container/footer/footer';
import Main from './container/main/main';
import './App.css'
import locale_en from "./compiled-lang/en-US.json";
import locale_id from "./compiled-lang/id.json";
import { IntlProvider } from 'react-intl';
import { Component } from 'react';

const data= {
  'en-US' : locale_en,
  'id' :locale_id
}

class App extends Component {
  constructor(){
    super();
    this.state= {
      locale: navigator.language.split(/[-_]/)[0],
    }
    this.languageHandle = this.languageHandle.bind(this)
    
  }

  languageHandle = (language) =>{
    this.setState({locale: language});
  }

  render(){
    return (
      <IntlProvider
        locale={this.state.locale}
        messages={data[this.state.locale]}
        >
          <div className="App">
            <Header languageHandle = {this.languageHandle} />
            <Main />
            <Footer />
          </div>
      </IntlProvider>
      
    );
  }
}

export default App;

