import { Box, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class Labtest extends Component{
    render(){
        return(
            <Box className='Body'>
                <Box className='LongTextContainer'>
                    <Typography variant='h1' className='LongTextHeader'>
                        <FormattedMessage id='HeaderLabTest' description='Header for Lab Test Section' defaultMessage='Lab Tests' />
                    </Typography>
                    <Typography className='LongTextContent'>
                    1. Test European Standards (EN: European Norms): Bacterial / Fungicidal and Virucidal Activity.
                    <br/>2.  National Laboratory.



                    </Typography>
                </Box>
            </Box>
        )
    }
}

export default Labtest