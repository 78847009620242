import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import About from './about/about';
import Contact from './contact/contact';
import Labtest from './labtest/labtest';
import Product from './product/product';
import Home from './home/home'

import "./main.css"
class Main extends Component{
    render(){
        return(
            <Box>
                <Switch>
                    <Route path='/about'>
                        <About />    
                    </Route> 
                    <Route path='/labtest'>
                        <Labtest />    
                    </Route>
                    <Route path='/contact'>
                        <Contact />    
                    </Route>  
                    <Route path='/product'>
                        <Product />    
                    </Route> 
                    <Route path='/'>
                        <Home />    
                    </Route> 
                </Switch>
            </Box>
        )
    }
}

export default Main