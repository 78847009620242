import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import WebFont from 'webfontloader';
import {createMuiTheme, CssBaseline, ThemeProvider} from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';


WebFont.load({
  google: {
    families: ['Gayathri:100,400,700', "sans-serif"]
  }
});


const theme = createMuiTheme({
  palette:{
    primary:{
      main: "#685044"
    },
    secondary:{
      main: "#0047AD"
    },
    type:"light",
    background:{
      default: "#FCF2DB"
    }
  },
  typography:{
    fontFamily: "Gayathri",
    h1: {
      color: "#0047AD",
      fontWeight: "100"
    },
    h2: {
      color: "#0047AD",
      fontWeight: "100"
    },
    h3: {
      color: "#0047AD",
      fontWeight: "100"
    },
    h4: {
      color: "#0047AD",
      fontWeight: "100"
    },
    h5: {
      color: "#0047AD",
      fontWeight: "400"
    },
    h6: {
      color: "#0047AD",
      fontWeight: "400"
    },
    body1: {
      color: "#040403",
      fontWeight: "100",
      lineHeight: "180%",
      fontSize: "22px"
    },
    body2: {
      color: "#040403",
      fontWeight: "400"
    },
    button: {
      textTransform: "none",
      fontWeight: "400",
      textAlign: "center",
    }
  },
  overrides:{
    MuiButton: {
      disableElevation: "true",
      label: {
        height: "25px",
        display: "flex",
        alignItems: "stretch",
      },
      root: {
        height: "50px",
        fontSize: "21px",
        minWidth:"100px"
      }
    },
    MuiPaper: {
      root:{
        backgroundColor: "#FCF2DB",
        padding: "2vh 5vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }
    },
    MuiSvgIcon:{
      root:{
        height: "100%"
      }
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Router>
          <App/>
        </Router>
    </ThemeProvider>
  </React.StrictMode>, document.getElementById('root'))
serviceWorker.unregister();
  