import React, { useState, useEffect } from 'react';
import { Button, Box, Paper, Collapse } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import {Link} from 'react-router-dom'
import aramilogo from "../../img/new arami.png";
import {FormattedMessage} from 'react-intl';
import "./header.css";

function Header (props){
    const [windowwidth, setWidth] = useState(window.innerWidth)
    const [opennav, setNav] = useState(false)
    const [openlang, setLang] = useState(false)
    const toggleNav = (e) =>{
        setNav(!opennav)
    }

    const toggleLang = (e) => {
        setLang(!openlang)
    }

    useEffect(()=>{setWidth(window.innerWidth)}, [])

    return (
        <Paper className="Header" elevation={5}>
            <Box width='max-content'>
                <img src={aramilogo} alt="Logo Arami" width="100px" />
            </Box>
            {windowwidth <= 906 ? (
                <Box>
                    <Button variant="text" color="primary" height='50px' onClick={(e) => toggleNav(e)}>Menu</Button>
                    <Collapse in={opennav}>
                    <Box className="Collapsible">
                        <Button variant="text" color="primary" height='50px' component={Link} to = '/'>
                            <FormattedMessage id="menuhome" description="home button" defaultMessage="Home" />
                        </Button>
                        <Button variant="text" color="primary" height='50px' component={Link} to = '/about'>
                            <FormattedMessage id="menuabout" description="about button" defaultMessage="About" />
                        </Button>
                        <Button variant="text" color="primary" height='50px' component={Link} to = '/product'>
                            <FormattedMessage id="menuproduct" description="product button" defaultMessage="Product" />
                        </Button>
                        <Button variant="text" color="primary" height='50px' component={Link} to = '/labtest'>
                            <FormattedMessage id="menulabtest" description="labtests button" defaultMessage="Lab Tests" />
                        </Button>
                        <Button variant="text" color="primary" height='50px' component={Link} to = '/contact'>
                            <FormattedMessage id="menucontact" description="contact button" defaultMessage="Contact" />
                        </Button>
                        <Button variant="contained" color="primary" endIcon={<ArrowDropDown/>} height='50px' onClick={(e) => toggleLang(e)}>
                            Select Language
                        </Button>
                        <Collapse in={openlang}>
                            <Button variant="contained" color="primary" disableElevation="true" height = "50px" onClick = {()=>props.languageHandle("en-US")}>English</Button>
                            <Button variant="contained" color="primary" disableElevation="true" height = "50px" onClick = {()=>props.languageHandle("id")}>Bahasa Indonesia</Button>
                        </Collapse>
                    </Box>
                    </Collapse>
                </Box>
                
                
            ): 
                <Box>
                <Button variant="text" color="primary" height='50px' component={Link} to = '/'>
                    <FormattedMessage id="menuhome" description="home button" defaultMessage="Home" />
                </Button>
                <Button variant="text" color="primary" height='50px' component={Link} to = '/about'>
                    <FormattedMessage id="menuabout" description="about button" defaultMessage="About" />
                </Button>
                <Button variant="text" color="primary" height='50px' component={Link} to = '/product'>
                    <FormattedMessage id="menuproduct" description="product button" defaultMessage="Product" />
                </Button>
                <Button variant="text" color="primary" height='50px' component={Link} to = '/labtest'>
                    <FormattedMessage id="menulabtest" description="labtests button" defaultMessage="Lab Tests" />
                </Button>
                <Button variant="text" color="primary" height='50px' component={Link} to = '/contact'>
                    <FormattedMessage id="menucontact" description="contact button" defaultMessage="Contact" />
                </Button>
                <Button variant="contained" color="primary" endIcon={<ArrowDropDown/>} height='50px' onClick={(e) => toggleLang(e)}>
                    Select Language
                </Button>
                <Collapse className="LanguageArea" in={openlang}>
                    <Button variant="contained" color="primary" disableElevation="true" height = "50px" onClick = {()=>props.languageHandle("en-US")}>English</Button>
                    <Button variant="contained" color="primary" disableElevation="true" height = "50px" onClick = {()=>props.languageHandle("id")}>Bahasa Indonesia</Button>
                </Collapse>
            </Box>
            }
            
            
        </Paper>

        )
}

export default Header