import { Box, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from "react-intl";
import logoara from '../../img/ara.png';
import './footer.css';

class Footer extends Component{
    render(){
        return (
            <Box className="Footer">
                <Box className="FooterGeneralInfo">
                    <img src={logoara} alt="Logo Ara" width='150px'/>
                </Box>
                
                <Box className="FooterNavigation">
                    <Box className="Box">
                        <Box className="Box">
                            <Link to ="/">
                                <FormattedMessage 
                                id = "NavigationHome"
                                defaultMessage ="Home"
                                />
                            </Link>
                            <Link to ="/about">
                                <FormattedMessage 
                                id = "NavigationAbout"
                                defaultMessage ="About"
                                />
                            </Link>
                            <Link to ="/product">
                                <FormattedMessage 
                                id = "NavigationProduct"
                                defaultMessage ="Our Product"
                                />
                            </Link>
                            <Link to ="/labtest">
                                <FormattedMessage 
                                id = "NavigationLabTest"
                                defaultMessage ="Lab Tests"
                                />
                            </Link>
                        </Box>
                        <br/>
                        <Box className="Box">
                            <Typography variant='subtitle2'>Copyright PT Ara Mulia Citra</Typography>
                        </Box>
                        
                    </Box>
                    <Box >
                        <Typography variant="subtitle2">
                            Jl. Batununggal Indah Raya No 217, Bandung 40266 West Java-Indonesia 
                            <br/>Email: contact@arami.com
                            <br/>Instagram: arami.id 
                            <br/>Facebook: arami id 
                            <br/>Youtube: arami id 
                        </Typography>
                    
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default Footer