import React, { Component } from 'react';
import { Box, Typography  } from '@material-ui/core';
import './cta.css'
import { FormattedMessage } from 'react-intl';
import logoarami from '../../../../img/new arami.png'
class CTA extends Component{
    render(){
        return(
            <Box className="CTA">
                <Box className="ContentText">
                    <Typography align='left' variant='h2'>
                        <FormattedMessage id="cta1" description="ctaheadline" defaultMessage="It is Possible to be Clean and Safe"/>
                    </Typography>
                    <Typography className='Subtitle' align='left' variant='h4'>
                        <FormattedMessage id="cta2" description="ctasubtitle" defaultMessage="Reach Out to Us" />
                        
                    </Typography>
                    <Typography variant="body1">
                            Jl. Batununggal Indah Raya No 217, Bandung 40266 West Java-Indonesia 
                            <br/>Email: contact@arami.com
                        </Typography>
                </Box>
                <Box className="ImageContainer">
                    <img src={logoarami} alt="logo arami" width='40%'/>
                </Box>
                
            </Box>
        )
    }
}

export default CTA