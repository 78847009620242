import { Box, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import './prodesc.css'
import {FormattedMessage} from 'react-intl'
class ProDesc extends Component{
    render(){
        return(
            <Box className='SubSection'>
                <Box className='Desc'>
                    <Box className="DescText">
                        <Typography variant='h4'>"ARAMI"</Typography>
                        <Typography>
                            <FormattedMessage 
                            id= "productdescription"
                            description="Short Product Description"
                            defaultMessage="Organic disinfectant, 100% natural ingredients, made from oyster shell, is a special product from Japan. Has been tested in an accredited laboratory. Used for hand sanitizer. Longer lasting effect than common disinfectants. Deactivates Norovirus and prevents the spread of viruses. Free of Alcohol, Free of Chlorine or Sodium Hypochlorite, No strong odor or carcinogenic substances. It is safe to use for washing vegetables, fruits and sterilizing cooking utensils. It can be used to clean and disinfect surfaces in hospitals, office buildings and hotels. Active Ingredients: Oyster Shell & Water Properties: Alkali Solution." />
                        </Typography>
                    </Box>
                </Box>
                <Box className='Desc'>
                    <Box className="DescText">
                        <Typography variant='h4'>
                            <FormattedMessage
                            id="UsesTitle"
                            description="Uses Title"
                            defaultMessage="Uses"/>
                        </Typography>
                        <Typography>
                        <FormattedMessage
                        id = "UsesText"
                        description="Uses Description"
                        defaultMessage="Hand Sanitizer, cleaning and disinfecting surfaces in hospitals, office buildings and hotels. Cleanses germs and bacteria on fruit and vegetables. Sterilizes household appliances such as: cooking utensils, cloth products, chairs, tables, door handles, fridges and furniture that are difficult to wash. In addition, Hand Sanitizer can treat acne because it can stop inflammation caused by bacteria in the pores."/>
                        </Typography>
                    </Box>
                </Box>
                <Box className='Desc'>
                    <Box className="DescText">
                        <Typography variant='h4'>
                            <FormattedMessage
                            id="InstructionTitle"
                            description= "Instruction Title"
                            defaultMessage="How to use"
                            />
                            </Typography>
                        <Typography>
                            <FormattedMessage
                            id="InstructionsHandSan"
                            description="Instruction for Hand Sanitizer"
                            defaultMessage="-Hand Sanitizer: Spray and rub onto palms."/> <br/>
                            <FormattedMessage
                            id="InstructionsFruits"
                            description="Instruction for Fruits, Vegetables and Cooking Utensils"
                            defaultMessage="-Fruit, vegetables and cooking utensils: Spray disinfectant then rinse with water for 30 seconds."/>
                         <br/>
                         <FormattedMessage
                            id="InstructionsSurfaces"
                            description="Instruction for Surfaces"
                            defaultMessage=" -Surfaces in hospitals, office buildings and hotels, household items, refrigerators, tables, chairs."/>
                       <br/>
                       <FormattedMessage
                            id="InstructionsDoors"
                            description="Instruction for Doors"
                            defaultMessage=" -Door handles: Spray and wipe with cloth."/>
                         <br/>
                         <FormattedMessage
                            id="InstructionsFace"
                            description="Instruction for Face"
                            defaultMessage="-Face: Spray only on the pimples, let stand until absorbed. "/>
                        <br/>
                        <FormattedMessage
                            id="InstructionsCloth"
                            description="Instruction for Cloth Products Face"
                            defaultMessage="-Cloth products such as dolls: spray disinfectant."/>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default ProDesc