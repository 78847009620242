import { Box, Typography } from '@material-ui/core';
import React, { Component } from 'react';

class Contact extends Component{
    render(){
        return(
            <Box className='Body'>
                <Box className='LongTextContainer'>
                    <Typography variant='h1' className='LongTextHeader'>
                        Contact Us
                    </Typography>
                    <Typography className='LongTextContent'>
                    Headquarters: Jl. Batununggal Indah Raya No 217, Bandung 40266 West Java-Indonesia 
                    <br/>Email: contact@arami.com
                    <br/>
                    <br/>Instagram: arami.id 
                    <br/>Facebook: arami id 
                    <br/>Youtube: arami id 


                    </Typography>
                </Box>
            </Box>
        )
    }
}

export default Contact