import React, { Component } from 'react';
import { Box, Typography } from '@material-ui/core';
import './overview.css'
import { FormattedMessage } from 'react-intl';

class Overview extends Component{
    render(){
        return(
        <Box className="Overview">
            <Box className="ContentText">
                <Typography align='center' variant='h3'>
                    <FormattedMessage id="overview1" description="overview title" defaultMessage="Oyster-Shell-Based Desinfectants"/>
                </Typography>
                <Typography align='center' variant='body1'>
                    <FormattedMessage id="overview2" description="overview" defaultMessage="Thousands of tons of oyster shell are being disposed in Hiroshima every year despite already using them for stock feedings. To reduce the disposal of the local product, our company considers the anti-bacterial traits of oyster shells and thus develop ARAMI to recycle them. This product is solely made of oyster shell and water, making it safe for both humanity and the environment." />
                </Typography>
            </Box>
        </Box>
        )
    }
}

export default Overview