import React, { Component } from 'react';
import { Box } from '@material-ui/core';
import './home.css';
import Hero from './hero/hero';
import Overview from './overview/overview';
import CTA from './cta/cta'

class Home extends Component{
    render(){
        return (
            <Box className="Body">
                <Hero />
                <Overview />
                <CTA />
            </Box>
            )
    }
}

export default Home